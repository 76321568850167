import {Fancybox} from "@fancyapps/ui";

document.addEventListener('submit', function (btn) {
    const form = btn.target.closest('form');
    if (typeof form.dataset.formAjax !== 'undefined') {
        btn.preventDefault();
        form.querySelector('input[type="submit"]').setAttribute('disabled', true);

        const formData = new FormData(form);
        const action = form.attributes.action.value;
        form.querySelectorAll('[name]').forEach(field => {
            field.classList.remove('is-error');
        });

        fetch(action, {
            method: 'POST',
            body: formData
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.status) {
                    Fancybox.close();
                    Fancybox.show([{
                        type: 'inline',
                        html: '<div class="form-answer">' + data.message + '</div>'
                    }]);
                    form.reset();
                } else {
                    for (let error in data.errors){
                        form.querySelector(`[name="${error}"]`).classList.add('is-error');
                    }
                    document.querySelector('.form-answer').innerHTML = data.message;
                }
                form.querySelector('input[type="submit"]').setAttribute('disabled', false);
            });
    }
});