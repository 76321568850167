import "./sprites";

import './modules/ToggleMenu';
import './modules/FormAjax';

import {Fancybox} from "@fancyapps/ui";
import InputMask from 'inputmask';


Fancybox.bind("[data-fancybox]", {
    dragToClose: false,
    on: {
        done: (fancybox, slide) => {
            const im = new InputMask('+7 (999)-999-99-99', {"clearIncomplete": true, "inputmode": "number"});
            im.mask($('[type="tel"],[data-mask="phone"]'));
        },
        init: () => {
            Fancybox.close();
        }
    }
});